import React, { Component } from "react";

class MSlider extends Component {

    componentDidMount(){
        this.slideWidth = document.querySelector('.mslideContainer').getBoundingClientRect().width/4
        console.log( document.querySelector('.mslideContainer').getBoundingClientRect().width, this.slideWidth)
    }

    render() {
        let mslider;
        let left = 0
        if(this.props.index * this.slideWidth){
           left =this.props.index * 30.62
        }else{
            left=0
        }

        if ( this.props.index === -1 ) {
            mslider = ( <div className="mslider" style={{ width: "30.62px", left: "0px" }}> </div> );
        } else {
            mslider = ( <div className="mslider" style={{ width: "30.62px", left, transition: "0.8s ease-in-out"}}> </div> );
        }

        return (
        <div className="msliderOuterContainer">
            <div className="mslideContainer"> { mslider } </div>
        </div>
        );
    }
}

export default MSlider;
