import React, { Component } from "react";
import "./MoreWork.scss";
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
export default class MoreWork extends Component {

  componentDidMount(){
          // or simply just AOS.init();
          AOS.init({
            delay: 50,
            once: false,
            duration: 600, // values from 0 to 3000, with step 50ms
            easing: 'ease-in-out', // default easing for AOS animations
          });
            // or simply just AOS.init();
  }

    constructor(){
        super()
        this.scrollPage();
    }

  // page scroll to top
  scrollPage() {
    window.scrollTo({
      top: 0
    });
  }
  render() {
    return (
      <section className="morework">
        <div className="homeBtn">
          <NavLink to="/">
            <button>Home</button>
          </NavLink>
        </div>

        <section className="MyWork">
          <div className="myWorkHeading">
            <h1>My Work</h1>
          </div>
          <div className="container">
            <div className="row">
              <div className="workGrid">
              <div  data-aos="fade-up" className="workCard">
              <img src={require("../../../../images/work/brewingstack.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.brewingstack.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
                <div data-aos="fade-up" className="workCard">
                  <img
                    src={require("../../../../images/work/work1.png")}
                    alt=""
                  />
                  <div className="workShadow">
                    <a
                      href="https://logipool.netlify.app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../images/view.png")}
                        alt=""
                      />
                    </a>
                    <h5>view Project</h5>
                  </div>
                </div>

                <div  data-aos="fade-up" className="workCard">
              <img src={require("../../../../images/work/shopping.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.behance.net/gallery/95197905/Shopping-website-design"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
                <div data-aos="fade-up" className="workCard">
                  <img
                    src={require("../../../../images/work/work2.png")}
                    alt=""
                  />
                  <div className="workShadow">
                    <a
                      href="https://www.behance.net/gallery/91952855/App-design"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../images/view.png")}
                        alt=""
                      />
                    </a>
                    <h5>view Project</h5>
                  </div>
                </div>
                <div data-aos="fade-up" className="workCard">
                  <img
                    src={require("../../../../images/work/work3.png")}
                    alt=""
                  />
                  <div className="workShadow">
                    <a
                      href="http://technogrowth.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../images/view.png")}
                        alt=""
                      />
                    </a>
                    <h5>view Project</h5>
                  </div>
                </div>
                <div data-aos="fade-up" className="workCard">
                  <img
                    src={require("../../../../images/work/work4.png")}
                    alt=""
                  />
                  <div className="workShadow">
                    <a
                      href="https://www.behance.net/gallery/91291241/Music-APP-neomorphism-soft-UI"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../images/view.png")}
                        alt=""
                      />
                    </a>
                    <h5>view Project</h5>
                  </div>
                </div>
                <div data-aos="fade-up" className="workCard">
                  <img
                    src={require("../../../../images/work/work5.png")}
                    alt=""
                  />
                  <div className="workShadow">
                    <a
                      href="https://www.behance.net/gallery/85778949/Login-and-dashboard-design"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../images/view.png")}
                        alt=""
                      />
                    </a>
                    <h5>view Project</h5>
                  </div>
                </div>
                <div data-aos="fade-up" className="workCard">
                  <img
                    src={require("../../../../images/work/work6.png")}
                    alt=""
                  />
                  <div className="workShadow">
                    <a
                      href="https://www.behance.net/gallery/90921877/Soft-UI-neumorphism"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../images/view.png")}
                        alt=""
                      />
                    </a>
                    <h5>view Project</h5>
                  </div>
                </div>
                <div data-aos="fade-up" className="workCard">
                  <img
                    src={require("../../../../images/work/work7.png")}
                    alt=""
                  />
                  <div className="workShadow">
                    <a
                      href="https://www.behance.net/gallery/93157425/Login-screen"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../images/view.png")}
                        alt=""
                      />
                    </a>
                    <h5>view Project</h5>
                  </div>
                </div>
                <div data-aos="fade-up" className="workCard">
                  <img
                    src={require("../../../../images/work/work8.png")}
                    alt=""
                  />
                  <div className="workShadow">
                    <a
                      href="https://www.behance.net/gallery/91851675/soft-UI-APP-form-design"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../images/view.png")}
                        alt=""
                      />
                    </a>
                    <h5>view Project</h5>
                  </div>
                </div>
                <div data-aos="fade-up" className="workCard">
                  <img
                    src={require("../../../../images/work/work9.png")}
                    alt=""
                  />
                  <div className="workShadow">
                    <a
                      href="https://www.behance.net/gallery/91056027/UIUX-Design"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../images/view.png")}
                        alt=""
                      />
                    </a>
                    <h5>view Project</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
