import React from "react";
import "./Footer.scss";

function Footer() {
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="LinkSection">
            <a
              href="https://www.linkedin.com/in/ravimrk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="btnLink linkedin">
                <img src={require("../../images/linkedin.png")} alt="" />
                <h5> Linkedin</h5>
              </div>
            </a>

            <a
              href="https://www.behance.net/RaviMrk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="btnLink behance">
                <img src={require("../../images/behance.png")} alt="" />
                <h5> Behance </h5>
              </div>
            </a>

            <a
              href="https://dribbble.com/RaviMrk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="btnLink dribble">
                <img src={require("../../images/dribbble.png")} alt="" />
                <h5> Dribbble </h5>
              </div>
            </a>

            <a
              href="mailto:raviparmar12316@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="btnLink mail">
                <img src={require("../../images/gmail.png")} alt="" />
                <h5 id="webView"> raviparmar12316@gmail.com </h5>
                <h5 id="phoneView">Gmail</h5>
              </div>
            </a>

            <a
              href="https://github.com/RaviMrk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="btnLink github">
                <img src={require("../../images/github.png")} alt="" />
                <h5> Github </h5>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
