import React from "react";
import "./App.css";
import { HashRouter, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Nav from "./components/Nav/Nav";
import Work from "./components/Home/MyWork/MoreWork/MoreWork";
import Footer from "./components/Footer/Footer";
import ReactGA from 'react-ga';


function App() {
  ReactGA.initialize('UA-162951395-1');
  ReactGA.pageview('/homepage');
  
  return (
    <HashRouter>
      <Nav />
      <Route path={"/"} exact component={Home} />
      <Route path={"/About"} component={About} />
      <Route path={"/work"} component={Work} />
      <Footer />
    </HashRouter>
  );
}

export default App;
