import React, { Component } from "react";
import "./Nav.scss";

export class Nav extends Component {
  render() {
    return (
      <section className="nav ">
      {/* <img src={require('../../images/menu.png')} alt=""/> */}
        <div className="aboutBtn">
        <a href="https://www.linkedin.com/in/ravimrk/" target="_blank" rel="noopener noreferrer">
        <button>About Me</button>
        </a>
        </div>
        <img id="chat" src={require('../../images/chat.png')} alt=""/>
      </section>
    );
  }
}

export default Nav;
