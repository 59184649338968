import React,{Component} from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

class Slider extends Component {

    shouldComponentUpdate(){
        return false
    }
    render(){
        return(
            <div className="contentSection">
                <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    autoplay
                    items = {1}
                    autoplayTimeout={2000}
                    autoplaySpeed = {1000}
                    onChanged={(e)=>{
                        this.props.getData(e.page.index)
                    }}
                >
                    <div className="item text-center">
                        <div className="container">
                            <h6>UI/UX</h6>
                            <div className="p-3 img-fluid">
                                <img src={require('../../../../images/mobile/UIUX.png')} alt="Request and estimate"/>
                            </div>
                        </div>
                    </div>
                    <div className="item text-center">
                        <div className="container">
                            <h6>App Development</h6>
                            <div className="p-4 img-fluid">
                            <img src={require('../../../../images/mobile/APP.png')} alt="Sign Contract and NDA"/>
                            </div>
                        </div>
                    </div>

                    <div className="item text-center">
                        <div className="container">
                            <h6>Web Development</h6>
                            <div className="p-3 img-fluid">
                                <img src={require('../../../../images/mobile/WEB.png')} alt="Schedule sprints"/>
                            </div>
                        </div>
                    </div>

                    <div className="item text-center">
                        <div className="container">
                            <h6>Software Development</h6>
                            <div className="p-3 img-fluid">
                                <img src={require('../../../../images/mobile/SOFTWARE.png')} alt="Prototype"/>
                            </div>
                        </div>
                    </div>


                </OwlCarousel>
            </div>
        )
    }
}

export default Slider;