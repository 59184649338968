import React from "react";
import "./Contact.scss";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useSignUpForm from './CustomHooks';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Contact({form,setform}) {
  const classes = useStyles();
  const signup = () => {
    console.log(inputs);
    const subject = "Inquery Email"
    const to = "raviparmar12316@gmail.com"
    const from = "r1231612316@gmail.com"
    const name = inputs.name
    const email= inputs.email
    const message = inputs.message
    axios.post("https://mysiteemail.herokuapp.com/email/sendEmail", {name, email, message,subject,to ,from }).then(result => {
      //access the results here....
      alert("Thank you so much....!  will contact you.");

    });
  }
  const {inputs, handleInputChange, handleSubmit} = useSignUpForm({name: '', email: '', message: ''}, signup);
  return (
    <section className="contact" style={form===1 ? {transform: 'scale(1, 1)'}:null}>
      <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off" >

        <div className="formGrid">
          <div className="inputSection">
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              type="text"
              name="name"
              onChange={handleInputChange} value={inputs.name}
            />
          </div>
          <div className="inputSection">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              type="email"
              name="email"
              onChange={handleInputChange} value={inputs.email}

            />
          </div>
          <div className="messageSection">
            <TextField
              id="outlined-multiline-static"
              label="Message...."
              multiline
              rows="2"
              variant="outlined"
              name="message"
              onChange={handleInputChange} value={inputs.message}

            />
          </div>
          <div className="btnSection">
            <button type="submit" onClick={()=>setform(0)}>Submit</button>
            <div className="cancelBtn" id="cancel" onClick={()=>setform(0)} >Cancel</div>
            {/* <button onClick={()=>setform(0)}>Cancel</button> */}
          </div>
        </div>
      </form>
    </section>
  );
}

export default Contact;
