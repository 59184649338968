import React, { Component } from 'react'
import './Home.scss'
import MainPgae from './MainPage/MainPage'
import MyWork from './MyWork/MyWork'

export class Home extends Component {
    render() {
        return (
<section className="home">

<MainPgae/>
<MyWork/>
</section>
        )
    }
}

export default Home
