import React from "react";
import "./MyWork.scss";
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
function MyWork() {
      // or simply just AOS.init();
      AOS.init({
        delay: 50,
        once: false,
        duration: 600, // values from 0 to 3000, with step 50ms
        easing: 'ease-in-out', // default easing for AOS animations
      });
        // or simply just AOS.init();
  return (
    <section className="MyWork">

    <div className="workBg1">
      <img src={require('../../../images/myworkBg.png')} alt=""/>
    </div>

      {/* <div className="workBg2">
        <img src={require('../../../images/background-circle.png')} alt=""/>
      </div> */}
      <div className="myWorkHeading">
        <h1>My Work</h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="workGrid">

          <div  data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/brewingstack.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.brewingstack.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
            <div  data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/work1.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://logipool.netlify.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
            <div  data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/shopping.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.behance.net/gallery/95197905/Shopping-website-design"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
            
            <div data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/work2.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.behance.net/gallery/91952855/App-design"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
            <div data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/work3.png")} alt="" />
              <div className="workShadow">
                <a
                  href="http://technogrowth.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
            <div data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/work4.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.behance.net/gallery/91291241/Music-APP-neomorphism-soft-UI"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
            {/* <div data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/work5.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.behance.net/gallery/85778949/Login-and-dashboard-design"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div> */}
            <div data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/work6.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.behance.net/gallery/90921877/Soft-UI-neumorphism"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
            <div data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/work7.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.behance.net/gallery/93157425/Login-screen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
            {/* <div data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/work8.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.behance.net/gallery/91851675/soft-UI-APP-form-design"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div> */}
            <div data-aos="fade-up" className="workCard">
              <img src={require("../../../images/work/work9.png")} alt="" />
              <div className="workShadow">
                <a
                  href="https://www.behance.net/gallery/91056027/UIUX-Design"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("../../../images/view.png")} alt="" />
                </a>
                <h5>view Project</h5>
              </div>
            </div>
          </div>

          <div className="viewMoreSection">

            <NavLink to="/work">
            <button>
              View More
            </button>
          </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MyWork;
