import React,{PureComponent} from 'react'
import Slider from './Slider'
import MSlider from './MSlider'
import './Mobile.scss'


class Mobile extends PureComponent{
    constructor(){
        super()
        this.state = {
            index : 0
        }
    }
    getData = (index) => {
        if(this.state.index !== index){
            this.setState({ index })
        }
    }
    render(){
        return(
            <div className="mobile">
                <div className="innerMobile">
                    <div className="topMobile mb-4">
                        <h1>What I DO?</h1>
                    </div>
                    <div className="centerMobile">
                        <Slider getData = {this.getData}/>
                        <MSlider index = {this.state.index}/>
                    </div>
                    <div className="bottomMobile">
                        <div className="mobileBtn">
                            <h3>Contact</h3>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Mobile