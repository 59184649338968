import React, { useState } from "react";
import "./MainPage.scss";
import Mobile from "./Mobile/Mobile";
import Contact from "../contact/Contact";
import AOS from "aos";
import "aos/dist/aos.css";
function MainPage() {
  const [form, setform] = useState(0);

  // or simply just AOS.init();
  AOS.init({
    delay: 50,
    once: false,
    duration: 600, // values from 0 to 3000, with step 50ms
    easing: "ease-in-out", // default easing for AOS animations
  });
  // or simply just AOS.init();

  return (
    <section className="mainpage">
      <Contact form={form} setform={setform} />
      <div className="backgoundImg1">
        <img src={require("../../../images/background-circle.png")} alt="" />
      </div>
      <div className="backgoundImg2">
        <img src={require("../../../images/background-circle.png")} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="leftSection">
              <div data-aos="fade-up" className="mainpageHeading">
                <h1>Hello</h1>
                <h5>
                  I Am Ravi A Freelance UI / UX Designer And Software Developer
                  ,
                  <br />
                  Who Builds Wonderful Products Experience .
                </h5>
                <div className="workLink">
                  <h5>See My Work on</h5>
                  <div className="linkImg">
                    <a
                      href="https://dribbble.com/RaviMrk"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../images/dribbble.png")}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://www.behance.net/RaviMrk"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../images/behance.png")}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/ravimrk_ui_ux_designer/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../images/instagram.png")}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="contactBtn">
                  <a
                    href="mailto:raviparmar12316@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                    // onClick={()=>setform(1)}
                    >
                      Contact
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div data-aos="zoom-in" className="RightSection">
              {/* <img src={require("../../../images/Rectangle 2.png")} alt="" /> */}
              <Mobile />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainPage;
